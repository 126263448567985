<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="loadingHeader" />
    <v-card>
      <v-card-text>
        <v-data-table
          elevation-1
          :headers="headers"
          :items="visibleOrders"
          @refresh="refresh"
          :expanded.sync="expanded"
          :loading="isLoading"
          show-expand
          item-key="id"
          :search="search"
          sort-by="created"
          :sort-desc="true"
          no-data-text="You have not placed any orders"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" lg="2">
                <DatePicker
                  v-model="deliveryDate"
                  label="Delivery Date"
                  :filled="false"
                  clearable
                  @input="refresh"
                />
              </v-col>
              <v-col cols="12" lg="2">
                <DatePicker
                  v-model="start"
                  label="From"
                  :filled="false"
                  :disabled="deliveryDate !== undefined"
                  @input="refresh"
                />
              </v-col>
              <v-col cols="12" lg="2">
                <DatePicker
                v-model="end"
                  label="To"
                  :filled="false"
                  :disabled="deliveryDate !== undefined"
                  @input="refresh"
                />
              </v-col>
              <v-col cols="11" lg="4">
                <v-text-field
                  clearable
                  data-cy="TableBasic-search"
                  class="mr-2"
                  v-model="search"
                  :append-icon="mdiMagnify"
                  :label="$t('common.search')"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col cols="2" class="d-flex justify-start align-center">
                <v-btn
                  icon color="primary" @click="refresh">
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDateTime(item.created) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 10px">
              <v-data-table
                :headers="lineHeaders"
                :items="item.cart"
                :loading="item.isLoading"
                elevation-0
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:item.price="{ item }">
                  {{ formatNumber(item.price) }}
                </template>
                <template v-slot:item.vat="{ item }">
                  {{ formatNumber(item.vat) }}
                </template>
                <template v-slot:item.total="{ item }">
                  {{ formatNumber(item.total) }}
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.updated="{ item }">
            {{ formatDateTime(item.updated) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex flex-column pa-2">
              <v-btn
                small
                color="success"
                class="mb-2"
                @click="reorder(item)"
                :loading="item.isSaving"
                block
              >
                Reorder
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiMagnify, mdiRefresh, mdiPencil } from '@mdi/js';
import dayjs from 'dayjs';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import DatePicker from '~src/components/DatePicker.vue';

export default {
  name: 'MyOrders',
  components: {
    ProgressLinearTimeout,
    DatePicker,
  },
  mixins: [loading],
  data() {
    return {
      mdiMagnify,
      mdiRefresh,
      mdiPencil,
      dialogDeliveryDate: false,
      editItem: {},
      isSavingItem: false,
      expanded: [],
      loadingHeader: false,
      deliveryDate: undefined,
      showCompleted: false,
      search: '',
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Orders',
        disabled: true,
        depth: 0,
      }],
      orders: [],
      orderHeaders: [],
      start: dayjs().add(-2, 'day').format('YYYY-MM-DD'),
      end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      lineHeaders: [
        {
          text: 'Quantity',
          value: 'amount',
        },
        {
          text: 'Item Code',
          value: 'itemCode',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Tax',
          value: 'tax',
        },
        {
          text: 'Total',
          value: 'total',
        },
      ],
      headers: [
        {
          text: 'Created',
          value: 'created',
        },
        {
          text: 'Order ID',
          value: 'externalOrderNo',
        },
        {
          text: 'Order No',
          value: 'orderNo',
        },
        {
          text: 'Delivery Date',
          value: 'deliveryDate',
        },
        // {
        //   text: 'Old Order ID',
        //   value: 'oldOrderId',
        // },
        // {
        //   text: 'Customer',
        //   value: 'customerCode',
        // },
        {
          text: 'Created By',
          value: 'createdBy',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
    visibleOrders() {
      if (this.showCompleted) {
        return this.orders;
      }
      if (this.deliveryDate) {
        return this.orders;
      }
      return this.orders.filter((o) => o.status !== 'Integrated');
    },
  },
  watch: {
    expanded() {
      this.loadOrderLines();
    },
  },
  async mounted() {
    this.SET_title('My Orders');
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItems']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    ...mapMutations('app', ['SET_title']),
    editDeliveryDate(item) {
      this.editItem = item;
      this.dialogDeliveryDate = true;
    },
    onDateFilter(date) {
      this.deliveryDate = dayjs(date).format('YYYY-MM-DD');
      this.refresh();
    },
    async loadOrderLines() {
      // this.expanded.forEach(async (item) => {
      //   if (item.cart) return;
      //   this.orders[this.orders.indexOf(item)].isLoading = true;
      // eslint-disable-next-line max-len
      //   const { data } = await this.$http.get(`/odata/orders?$filter=Id eq ${item.id}&$select=Id,Cart&$expand=Cart($select=amount,itemCode,price,tax,total)`);
      //   // eslint-disable-next-line prefer-destructuring
      //   this.orders[this.orders.indexOf(item)].cart = data.value[0].cart;
      //   this.orders[this.orders.indexOf(item)].isLoading = false;
      // });
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_stockItems();
        })(),
        (async () => {
          const { data } = await this.$http.get('/api/orders/my');
          this.orders = data.map((order) => ({
            ...order,
            isSaving: false,
            isLoading: false,
          }));
        })(),
      ]);
      this.isLoading = false;
    },

    async reorder(item) {
      item.cart.forEach(async (line) => {
        const stockItem = this.stockItems.find((si) => si.itemCode === line.itemCode);
        if (!stockItem) {
          this.$emit('toast:error', `Item ${line.itemCode} not found, skipping`);
          return;
        }
        this.ADD_TO_CART({
          stockItem,
          amount: line.amount,
        });
      });
      this.$emit('toast:success', 'Order added to cart');
      this.$router.push({ name: 'shop-Cart' });
    },
  },
};
</script>
<style scoped>
.table-line {
  width: 100%;
  border-collapse: collapse;
}
</style>
